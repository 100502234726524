import React from 'react';
import '../css/footer.css';


export default function Footer() {
  return (
    <footer>
      <p>&copy; 2021 &mdash; Michael Abatemarco</p>
    </footer>
  )
}
